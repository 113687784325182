import ResponseBase from "@/types/ResponseBase"
import CompanyProfile from "@/types/aboutUs/CompanyProfile"
import DiamondBaseAPI from "@/api/Api"
import CompanyLocation from "@/types/aboutUs/CompanyLocation"
import CompanyHistory from "@/types/aboutUs/CompanyHistory"
import CompanyVision from "@/types/aboutUs/CompanyVision"
import CompanyMission from "@/types/aboutUs/CompanyMission"
import CompanyCoreValue from "@/types/aboutUs/CompanyCoreValue"
import CompanyStandard from "@/types/aboutUs/CompanyStandard"
import CompanyPolicy from "@/types/aboutUs/CompanyPolicy"
import CompanyActivity from "@/types/aboutUs/CompanyActivity"
import CompanyCSR from "@/types/aboutUs/CompanyCSR"
import CompetitionStrategy from "@/types/aboutUs/CompetitionStrategy"

export default class AboutUsAPI extends DiamondBaseAPI {
  static getCompanyProfile(): Promise<ResponseBase<CompanyProfile>> {
    return this.api.get("company").then(({ data }) => data)
  }

  static getCompanyLocation(): Promise<ResponseBase<CompanyLocation>> {
    return this.api.get("location").then(({ data }) => data)
  }

  static getCompanyHistory(): Promise<ResponseBase<CompanyHistory>> {
    return this.api.get("history").then(({ data }) => data)
  }

  static getCompanyVision(): Promise<ResponseBase<CompanyVision>> {
    return this.api.get("vision").then(({ data }) => data)
  }

  static getCompanyMission(): Promise<ResponseBase<CompanyMission>> {
    return this.api.get("mission").then(({ data }) => data)
  }

  static getCompanyCoreValue(): Promise<ResponseBase<CompanyCoreValue>> {
    return this.api.get("core_value").then(({ data }) => data)
  }

  static getCompanyStandard(): Promise<ResponseBase<CompanyStandard>> {
    return this.api.get("standard").then(({ data }) => data)
  }

  static getCompanyPolicy(): Promise<ResponseBase<CompanyPolicy>> {
    return this.api.get("policy").then(({ data }) => data)
  }

  static getCompanyActivity(): Promise<ResponseBase<CompanyActivity>> {
    return this.api.get("activity").then(({ data }) => data)
  }

  static getCompanyCSR(): Promise<ResponseBase<CompanyCSR>> {
    return this.api.get("csr").then(({ data }) => data)
  }

  static getCompetitionStrategy(): Promise<ResponseBase<CompetitionStrategy>> {
    return this.api.get("strategy").then(({ data }) => data)
  }
}
