import { render, staticRenderFns } from "./AboutUs.vue?vue&type=template&id=c86d1eea&scoped=true&"
import script from "./AboutUs.vue?vue&type=script&lang=ts&"
export * from "./AboutUs.vue?vue&type=script&lang=ts&"
import style0 from "./AboutUs.vue?vue&type=style&index=0&id=c86d1eea&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c86d1eea",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VHover } from 'vuetify/lib/components/VHover';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCol,VContainer,VFadeTransition,VHover,VRow,VSelect,VSlideXTransition,VTab,VTabs,VTabsSlider})
