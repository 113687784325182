import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class CompanyLocation {
  @JsonProperty("call_center") callCenter?: string

  @JsonProperty() email?: string

  @JsonProperty("first_branch") firstBranch?: string

  @JsonProperty() headquarters?: string

  @JsonProperty("second_branch") secondBranch?: string

  @JsonProperty() status?: string

  @JsonProperty("third_branch") thirdBranch?: string

  @JsonProperty() website?: string
}
