import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class CompanyStandardCategory {
  @JsonProperty("standard_category_id") id?: number

  @JsonProperty("standard_category") detail?: string

  @JsonProperty() title?: string

  @JsonProperty() status?: string

  @JsonProperty({ type: DiamondFile }) file?: DiamondFile
}
