import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class CompanyProfile {
  @JsonProperty({ name: "company_name" }) companyName?: string

  @JsonProperty({ name: "company_no" }) companyNumber?: string

  @JsonProperty({ name: "company_shortname" }) companyShortName?: string

  @JsonProperty({ name: "registered_capital" }) registeredCapital?: string

  @JsonProperty({ name: "type_business" }) typeBusiness!: string
}
