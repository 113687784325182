
















import { Component, Vue, Watch } from "vue-property-decorator"
import AboutUsAPI from "@/api/aboutUs/aboutUs"
import { deserialize } from "typescript-json-serializer"
import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"
import CompetitionStrategy from "@/types/aboutUs/CompetitionStrategy"
// @ts-ignore
import AOS from "aos"

@Component({
  name: "CompetitionStrategy",
  components: { SectionUnderLine }
})
export default class CompetitionStrategyPage extends Vue {
  private data: CompetitionStrategy = new CompetitionStrategy()

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    AboutUsAPI.getCompetitionStrategy()
      .then(({ data }) => {
        this.data = deserialize<CompetitionStrategy>(data, CompetitionStrategy)
      })
      .then(() => {
        AOS.refresh()
      })
  }

  mounted() {
    this.fetchData()
  }
}
