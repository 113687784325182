import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class CompanyActivity {
  @JsonProperty("activity_id") activityId?: number

  @JsonProperty() activity?: string

  @JsonProperty() file?: DiamondFile
}
