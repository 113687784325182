



























































































































import { Component, Watch } from "vue-property-decorator"
import AboutUsAPI from "@/api/aboutUs/aboutUs"
import { deserialize } from "typescript-json-serializer"
import CompanyStandard from "@/types/aboutUs/CompanyStandard"
import CompanyPolicy from "@/types/aboutUs/CompanyPolicy"
import CompanyActivity from "@/types/aboutUs/CompanyActivity"
import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"
import _ from "lodash"
// @ts-ignore
import AOS from "aos"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "StandardPage",
  components: { SectionUnderLine }
})
export default class StandardPage extends mixins(BreakpointMixin) {
  private standard: CompanyStandard = new CompanyStandard()

  private policy: CompanyPolicy = new CompanyPolicy()

  private activities: CompanyActivity[] = []

  get activityImageWidth() {
    const bp = this.$vuetify.breakpoint
    let size = 0
    if (bp.lgAndDown) {
      size = 180
    } else {
      size = 269
    }
    return `${size}px`
  }

  get activityImageHeight() {
    const bp = this.$vuetify.breakpoint
    let size = 0
    if (bp.lgAndDown) {
      size = 90
    } else {
      size = 134
    }
    return `${size}px`
  }

  get standardImageSize() {
    const bp = this.$vuetify.breakpoint
    let size = 0
    if (bp.lgAndDown) {
      size = 180
    } else {
      size = 269
    }
    return `${size}px`
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    const p1 = AboutUsAPI.getCompanyStandard().then(({ data }) => {
      this.standard = deserialize<CompanyStandard>(data, CompanyStandard)
    })
    const p2 = AboutUsAPI.getCompanyPolicy().then(({ data }) => {
      this.policy = deserialize<CompanyPolicy>(data, CompanyPolicy)
    })
    const p3 = AboutUsAPI.getCompanyActivity().then(({ data }) => {
      this.activities = _.map(data, (d) => deserialize<CompanyActivity>(d, CompanyActivity))
    })
    Promise.all([p1, p2, p3]).then(() => {
      AOS.refresh()
    })
  }

  mounted() {
    this.fetchData()
  }
}
